import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class LicenseeService {

    constructor(
        private apiService: ApiService,
    ) {
    }

    createLicensee(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/createLicensee', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editLicensee(data): Observable<any> {
        return this.apiService.get('/api/v1/admin/editLicensee', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    deleteLicensee(data): Observable<any> {
        return this.apiService.delete('/api/v1/admin/deleteLicensee', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateLicensee(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/updateLicensee', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getLicensees(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/licensees', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }



}
