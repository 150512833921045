import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private apiService: ApiService
    ) {
    }
    loginUser(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/login', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    logout(data): Observable<any> {
        return this.apiService.get('/api/v1/admin/logout', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    forgotPassword(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/forgotPassword', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    addRole(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/addRole', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateRole(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/updateRole', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editRole(data): Observable<any> {
        return this.apiService.get('/api/v1/admin/editRole', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteRole(data): Observable<any> {
        return this.apiService.delete('/api/v1/admin/deleteRole', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    resetPassword(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/resetPassword', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getRoles(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/getRoles', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


    createAdmin(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/createAdmin', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editAdmin(data): Observable<any> {
        return this.apiService.get('/api/v1/admin/editAdmin', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    deleteAdmin(data): Observable<any> {
        return this.apiService.delete('/api/v1/admin/deleteAdmin', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateAdmin(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/updateAdmin', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getAdmins(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/admins', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    roles(): Observable<any> {
        return this.apiService.get('/api/v1/admin/roles')
            .pipe(map(
                data => {
                    return data;
                }
            ));
    }


    getBoothCategories(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/getBoothCategories', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


    createBoothCategory(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/createBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editBoothCategory(data): Observable<any> {
        return this.apiService.get('/api/v1/admin/editBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    deleteBoothCategory(data): Observable<any> {
        return this.apiService.delete('/api/v1/admin/deleteBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateBoothCategory(data): Observable<any> {
        return this.apiService.post('/api/v1/admin/updateBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


}
