<div id="sidebar-wrapper" data-simplebar="" data-simplebar-auto-hide="true">
  <div class="brand-logo">
    <a [routerLink]="['/']">
      <img src="assets/images/logo1.png" style="width: 150px;" alt="logo icon">
    </a>
  </div>
  <ul class="sidebar-menu do-nicescrol">
    <li class="sidebar-header">MAIN NAVIGATION</li>
    <li routerLinkActive="active">
      <a [routerLink]="['/licensees']" routerLinkActive="active">
        <i class="fas fa-id-card"></i> <span>Licensees Management</span>
      </a>
    </li>
  </ul>

</div>